<template>
  <div class="home">
    <v-container fluid fill-height class="loginOverlay">
      <login-and-forgot />
    </v-container>
  </div>
</template>

<script>
import LoginAndForgot from "@/components/Frontend/LoginAndForgot.vue";
export default {
  name: "Home",
  components: {
    LoginAndForgot,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.loginOverlay {
  background: rgba(255, 255, 255, 0);
}

.home {
  xxheight: 100vh;
  min-height: 100%;
  // background-image: url("../../assets/img/medsafu_hd_bg.png");
  background-size: cover;
  overflow: hidden;
}
</style>
