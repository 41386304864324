<template>
  <v-container class="fill-height">
    <base-dialog-notification ref="confirm" />
    <v-row align="center" justify="center">
      <v-form lg="12" md="12" cols="12">
        <v-window
            v-model="step"
            touchless
            :touch="{left: null, right: null}"
        >
          <v-window-item :value="0">
            <v-row>
              <v-col cols="12">
                <v-card-title class="py-8">
                  <div class="text-center row justify-center">
                    <h1
                      class="font-weight-light"
                      style="color: #3c4858; font-size: 36px"
                    >
                      <img
                        width="40%"
                        src="@/assets/FairwageLogo.png"
                        alt="avatar"
                        class="contain"
                      />
                    </h1>
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-form
                    ref="signinFrm"
                    @submit.prevent="loginUser"
                    lazy-validation
                  >
                    <register-mobile-number-input
                        ref="signInMobileNumberInput"
                        @updateMobileNumberInput="mobileNumberFunc"
                        :allowTestNumbers="true"
                    />
                    <v-text-field
                      v-model="signin.password"
                      :append-icon="toggled == true ? 'mdi-eye' : 'mdi-eye-off'"
                      :append-icon-cb="switchToggle"
                      label="Password"
                      name="password"
                      :type="toggled == true ? 'text' : 'password'"
                      :rules="[!!signin.password || 'Password is Required']"
                      required
                      outlined
                      @click:append="switchToggle"
                    />
<!--                    <vue-recaptcha-->
<!--                        ref="recaptcha"-->
<!--                        @verify="onVerify"-->
<!--                        @expired="onExpired"-->
<!--                        sitekey="6LctxIYkAAAAAF1JFgz-HiwnJjFr8Wry4pEaGf3k"-->
<!--                        class="recaptcha"-->
<!--                        @click="executeRecaptcha"-->
<!--                        :loadRecaptchaScript="true"-->
<!--                      >-->
<!--                      </vue-recaptcha>-->
                    <div>
                      <v-btn
                        ref="signInbutton"
                        color="secondary fairwage_dark_grey--text"
                        elevation="0"
                        block
                        large
                        type="submit"
                        :loading="submitLoading"
                        :disabled="submitLoading"
                        >Sign in</v-btn>
                      <!-- <social-login /> -->
                      <v-btn
                          ref="registerButton"
                          class="mt-6"
                          color="primary"
                          elevation="0"
                          dark
                          block
                          large
                          type=""
                          @click="$router.push('/register')"
                      >Register</v-btn>
                      <v-btn
                          ref="showForgotPasswordButton"
                          class="mt-6"
                          color="warning"
                          elevation="0"
                          outlined
                          block
                          large
                          type="button"
                          @click="showForgotPassword"
                      >Forgot Your Password?</v-btn>
                    </div>
                  </v-form>
                  <v-card-actions>
                  <div class="flex-grow-1" />
                  <v-row v-if="ping" class="mt-5">
                    <div class="mt-1 mb-1">Connection:</div>
                    <v-icon color="green accent-3" class="mx-3">
                      mdi-access-point
                    </v-icon>
                  </v-row>
                  <v-row v-else class="mt-5">
                    <div>Your internet is disconnected</div>
                    <v-icon color="error" class="mx-3">
                      mdi-access-point
                    </v-icon>
                  </v-row>
                </v-card-actions>
                </v-card-text>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :value="1">
            <v-row align="center" justify="center">
              <v-col cols="12" sm="4" md="4">
                <v-card-title class="py-10">
                  <div class="text-center row justify-center">
                    <v-row align="center" justify="center">
                      <h1
                        class="font-weight-light"
                        style="color: #3c4858; font-size: 36px"
                      >
                        <img
                          width="60%"
                          src="@/assets/otp_image.png"
                          alt="avatar"
                          class="contain"
                        />
                      </h1>
                    </v-row>
                    <v-card-text class="py-5" :items="userData">
                      <h2 class="text-center text-md-h3 primary--text my-4">
                        Verify your Mobile Number
                      </h2>
                      <p class="text-h4" style="word-break: break-word">
                        An 6 digit OTP has been sent to your mobile phone.<br/> Please enter the 6 digits here.
                      </p>

                      <v-row>
                        <v-col cols="12">
                          <v-otp-input
                            v-model="verifyOTP.code"
                            length="6"
                            @finish="verifyPin(userData)"
                            type="number"
                          ></v-otp-input>
                        </v-col>
                      </v-row>

                   
                      <div class="text-center mt-4">
                        <v-btn
                            elevation="0"
                            block
                            large
                            color="secondary fairwage_dark_grey--text"
                            class=""
                            @click="verifyPin(userData)"
                        >Verify</v-btn>
                        <v-btn
                          elevation="0"
                          block
                          large
                          color="primary"
                          class="my-6"
                          @click="resendOTP(userData.user.id)"
                        >Didn’t get the message? Resend</v-btn>
                        <v-btn
                            elevation="0"
                            block
                            large
                            outlined
                            color="warning"
                            class="my-6"
                            @click="step = 0"
                        >CANCEL</v-btn>

                      </div>

                    </v-card-text>
                  </div>
                </v-card-title>
                <v-card-text> </v-card-text>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :value="2">
            <v-row>
              <v-col cols="12">
                <v-card-text class="py-5">
                  <h2 class="text-center text-md-h3 primary--text my-4">
                    Forgot Your Password?
                  </h2>
                  <p class="text-h4 pt-2" style="word-break: break-word">
                    Please enter the mobile number that you registered with and we will SMS you a link to reset your password.
                  </p>
                  <v-form @submit.prevent="forgotPassword">
                    <register-mobile-number-input
                        ref="forgotPasswordMobileNumberInput"
                        @updateMobileNumberInput="forgotPasswordNumberFunc"
                        :allowTestNumbers="true"
                        class="pt-4"
                    />
                    <div class="text-center mt-4">
                      <v-btn
                          elevation="0"
                          block
                          large
                          color="secondary fairwage_dark_grey--text"
                          class=""
                          type="submit"
                          :loading="forgotPasswordLoading"
                      >RESET PASSWORD</v-btn>
                      <v-btn
                          elevation="0"
                          block
                          large
                          outlined
                          color="primary"
                          class="my-6"
                          @click="step = 0"
                      >BACK TO SIGN IN</v-btn>

                    </div>
                  </v-form>
                </v-card-text>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-form>
  
    </v-row>
  </v-container>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";
import VueRecaptcha from "vue-recaptcha";
import RegisterMobileNumberInput from "../Base/RegisterMobileNumberInput.vue";

export default {
  name: "Register",
  components: {
    VueRecaptcha,
    RegisterMobileNumberInput
  },
  data: () => ({
    toggled: false,
    ping: "",
    vueAppRecaptchaSiteKey: process.env.VUE_RECAPTCHA_SITE_KEY,
    vueAppRecaptchaSecretKey: process.env.VUE_RECAPTCHA_SECRET_KEY,
    step: 0,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        /.+@.+\..+/.test(v) || "Please make sure it is a valid email address",
    ],
    telNumberRules: [(v) => !!v || "Sorry your Mobile Number is required"],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 6) || "minimum 6 characters",
    ],
    signin: {
      telNumber: "",
      password: "",
    },
    forgot: {
      email: "",
      telNumber: "",
    },
    successMsg: "",
    errorMsg: "",
    valid: true,
    submitLoading: false,
    forgotPasswordLoading: false,
    message: "",
    dialog: false,
    userData: {},
    verifyOTP: {
      code: "",
    },
  }),
  apollo: {
    ping: {
      query: gql`
        query {
          ping
        }
      `,
      fetchPolicy: "network-only",
      result(data, key) {
        console.log(data, key);
      },
    },
  },
  computed: {

  },
  methods: {
    mobileNumberFunc(value) {
      this.signin.telNumber = value
    },
    forgotPasswordNumberFunc(value) {
      this.forgot.telNumber = value
    },
    isDisabled() {
      if(this.valid === true){
        return this.step=0;
      }
    },
    showForgotPassword() {
      this.step = 2
    },
    switchToggle() {
      this.toggled = !this.toggled;
    },
    digitValidate(ele) {
      console.log(ele.value);
      ele.value = ele.value.replace(/[^0-9]/g, "");
    },
    tabChange(val) {
      let ele = document.querySelectorAll("input");
      if (ele[val - 1].value != "") {
        ele[val].focus();
      } else if (ele[val - 1].value == "") {
        ele[val - 2].focus();
      }
    },
    executeRecaptcha() {
      this.$refs.recaptcha.execute();
    },
    onVerify: function (response) {
      console.log("Verified");
      return (this.valid = !this.valid);
    },
    onExpired: function () {
      console.log("Expired");
      return (this.valid = !this.valid);
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
    async warningDialog() {
      await this.$refs.confirm.open("Sorry", "The 6 digit OTP you entered was incorrect. Please try again.")
    },
    async loginUser() {
      this.submitLoading = true
      const self = this;
      if (!self.$refs.signinFrm.validate()) {
        this.submitLoading = false
        await this.$refs.confirm.open('Sorry', 'All fields need to be filled in. Please try again.', 'warning')
        return;
      }
      try {
        const userRecord = await this.$apollo.mutate({
          mutation: gql`
            mutation ($telNumber: String!, $password: String!) {
              login(telNumber: $telNumber, password: $password) {
                user {
                  id
                  email
                  role
                  telNumber
                }
                token
                OTP
              }
            }
          `,
          variables: {
            telNumber: this.signin.telNumber,
            password: this.signin.password,
          },
        });

        if (userRecord) {
          this.submitLoading = false
          this.dialog = true;
          this.userData = userRecord?.data?.login;
          this.step =1
          this.valid= true;
        }
      } catch (e) {
        this.submitLoading = false
        console.error(`Login Failure: ${JSON.stringify(e)}`);
        if (_.get(e, "graphQLErrors[0].extensions.code", null) === "UNAUTHENTICATED") {
          await this.$refs.confirm.open('Sorry', e.graphQLErrors[0].message, 'warning')
        } else {
          await this.$refs.confirm.open('Sorry', e.graphQLErrors[0].message, 'error')
        }
        self.errorMsg = `${e.message}`;
      }
    },

    async verifyPin(userData) {
      const self = this;
      const code = this.verifyOTP.code;
      try {
        if (code == userData?.OTP) {
          this.$token = userData?.token;
          this.$role = userData?.user?.role;
          localStorage.setItem("token", this.$token);
          localStorage.setItem("role", userData?.user?.role);

          if (this.$role == "employee") this.$router.push("/user/landing");
          if (this.$role == "employer") this.$router.push("/employer/landing");
          if (this.$role == "admin") this.$router.push("/admin/dashboard");
          if (this.$role == "consultant")
            this.$router.push("/consultant/users");

        } else {
          await this.$refs.confirm.open('Sorry', 'The 6 digit OTP you entered was incorrect. Please try again.', 'warning')
        }
      } catch (e) {
        console.error(`Verification Failed ${JSON.stringify(e)}`);
        await this.$refs.confirm.open('Sorry', e.message, 'error')
        self.errorMsg = `${e.message}`;
      }
    },

    async resendOTP(id) {
      const self = this;
      try {
        const query = gql`
          query ($id: ID!) {
            resendVerifyOTP(id: $id) {
              status
              OTP
            }
          }
        `;
        const userRecord = await this.$apollo.query({
          query: query,
          variables: {
            id: id,
          },
          fetchPolicy: 'network-only'
        });
        if (userRecord.data?.resendVerifyOTP?.status) {
          this.userData.OTP = userRecord?.data?.resendVerifyOTP?.OTP;
          await this.$refs.confirm.open('Success', 'A new 6 digit OTP has been sent to you mobile phone. Please check your SMS messages.', 'success')
        }
      } catch (e) {
        console.log(`Resend OTP Failed ${JSON.stringify(e)}`);
        await this.$refs.confirm.open('Sorry', e.graphQLErrors[0].message, 'error')
        self.errorMsg = `${e.message}`;
      }
    },

    async forgotPassword() {
      const self = this;
      this.forgotPasswordLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation ($telNumber: String!) {
            getResetPasswordLink(telNumber: $telNumber)
          }
        `,
        variables: {
          telNumber: this.forgot.telNumber,
        },
      }).then((response) => {
        this.forgotPasswordLoading = false
        this.$refs.confirm.open('Success', `A password reset link has been sent via SMS to your mobile phone: ${this.forgot.telNumber}`, 'success')
      }).catch((error) => {
        console.log('Error', error)
        this.forgotPasswordLoading = false
        self.errorMessage = _.get(error, 'graphQLErrors[0].message', `Sorry, there was a system error. Please try again or contact support. ${error}`);
        this.$refs.confirm.open('Error', self.errorMessage, 'error')
      })
    },
  },
};
</script>
<style>
.btn {
  text-transform: unset !important;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  background: #fff;
}
/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}
/* Style the buttons inside the tab */
.tab a {
  background-color: inherit;
  float: right;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
  text-decoration: none;
  border: 1px solid;
}
/* Change background color of buttons on hover */
.tab a:hover {
  background-color: #ddd;
}
/* Create an active/current tablink class */
.tab a.active {
  background-color: #ccc;
}
/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.loginsuccess-container {
  padding: 20px;
  margin: 0 auto;
  width: 80%;
  box-shadow: beige;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  word-break: break-all;
}
.main-container {
  margin-top: 10%;
}
.box-container {
  padding: 20px;
  margin: 0 auto;
  width: 400px;
  box-shadow: beige;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
}
.heading {
  text-align: center;
  font-weight: 300;
  color: #444;
  margin: 0 auto 45px;
  font-size: 35px;
  line-height: 38px;
  text-transform: none;
  letter-spacing: 0;
}
.form-fields,
.form-fields button {
  width: 100%;
  margin: 5px 0;
  line-height: 28px;
  border-radius: 5px;
}
.form-fields input {
  width: 100%;
  line-height: 40px;
  border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  background: #fff;
  padding: 0 5px;
  font-size: 14px;
}
.signIn {
  padding: 10px 32px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  background: #15cd72;
  text-align: center;
  cursor: pointer;
  height: auto;
  -webkit-appearance: none;
}
.createaccount {
  padding: 15px;
  background-color: #0069ff;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
  text-align: center;
  border-radius: 5px;
}
.center {
  text-align: center;
}
.login-choice span {
  color: #5b6987;
  display: -ms-grid;
  display: grid;
  font-size: 16px;
  width: 100%;
  line-height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -ms-grid-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  grid-template-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  grid-gap: 19px;
}
.login-choice span:after,
.login-choice span:before {
  content: "";
  border-top: 1px solid #e5e8ed;
}
.signup-buttons {
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}
.facebook-signup,
.google-signup {
  color: #031b4e;
  background: #f2f8ff;
  border: 1px solid rgba(0, 105, 255, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
  display: inline-block;
  margin-top: 0;
  width: 47.5%;
  padding: 15px;
  text-align: center;
  position: inherit;
}
.signup-buttons a {
  vertical-align: middle;
  text-decoration: none;
}
.signup-buttons svg {
  left: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.footer,
.footer a {
  text-align: center;
  color: #000;
}

body {
  background: #eee;
}

.bgWhite {
  background: white;
  box-shadow: 0px 3px 6px 0px #cacaca;
}

.title {
  font-weight: 600;
  margin-top: 20px;
  font-size: 24px;
}

.customBtn {
  border-radius: 0px;
  padding: 10px;
}

form input {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: left;
}

</style>
